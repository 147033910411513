<template>
  <div class="position-container col-10 pl-0">
    <div>
      <slot name="nav-position-items" />
    </div>
  </div>
</template>
<script>

import { BRow, BCol } from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.mintui-nav-position-column-principal{
  display: flex;
  align-content: center;
  border-right: 2px solid rgb(187,216,217);
}

.mintui-nav-position-column-secondary {
  display: flex;
  align-content: center;
}

.aiops-main-item-brow{
  display: flex;
  align-content: center;
  height: 4.11vw;
}

.position-container {
  background-color: rgb(221,237,238);
}

</style>
