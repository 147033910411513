<template>
  <div
    :class="backColorAiops"
  >
    <AiopsMenu />
    <WelcomeTeis
      v-if="this.$store.state.productAiops.aiops.showWelcome"
    />
    <NavBarPosition
      v-if="!this.$store.state.productAiops.aiops.showWelcome && this.$store.state.productAiops.aiops.logged"
      :breadCrumb="this.$store.state.productAiops.dataBreadCrumb"
    />
    <b-container
      class="col-lg-11 aiops_layout_content"
    >
      <router-view />
    </b-container>
  </div>
</template>
<script>
import { BContainer } from 'bootstrap-vue'
import { isUserLoggedIn } from '@/auth/utils'
import store from '@/store'
import AiopsMenu from './aiops-menu/AiopsMenu.vue'
import WelcomeTeis from './welcome-teis/WelcomeTeis.vue'
import NavBarPosition from './nav-position/NavPosition.vue'

export default {
  components: {
    BContainer,
    AiopsMenu,
    WelcomeTeis,
    NavBarPosition,
  },
  data() {
    return {

    }
  },
  computed: {
    backColorAiops() {
      return this.$store.state.productAiops.aiops.logged === true ? 'backlogged' : 'backLogin'
    },
  },
  created() {
    this.verifyToken()
  },
  methods: {
    verifyToken() {
      const isLoggedIn = isUserLoggedIn()
      const hasReloaded = this.$store.state.productAiops.aiops.logged
      if (isLoggedIn && !hasReloaded) {
        this.$store.commit('productAiops/logged', true)
        this.refreshToken()
      } else {
        this.errorRequest()
      }
    },
    refreshToken() {
      store.dispatch('productAiops/refreshToken')
        .then(res => {
          const timeToCall = res - 2
          if (store.state.productAiops.refreshToken) {
            setTimeout(() => {
              this.refreshToken()
            }, timeToCall * 1000)
          }
        })
        .catch(() => {
          this.errorRequest()
        })
    },
    errorRequest() {
      store.dispatch('productAiops/errorRequest')
    },
  },
}
</script>

<style lang="scss" scoped>
.aiops_layout_content {
  padding-left: 2rem;
  padding-right: 2rem;
}

</style>
