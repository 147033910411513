<template>
  <div
    class="col-12 backLogin padding-top-66 padding-bottom-64"
    align-h="center"
  >
    <div class="aiops_layout_content container col-lg-9">
      <b-row>
        <b-col
          class="col-sm-12 col-md-3"
        >
          <img
            class="d-block aiops_img_login"
            :src="require('@/assets/images/aiops/logo/IllustrationHeandphones.svg')"
            alt="image slot"
          >
        </b-col>

        <!-- password -->
        <b-col
          class="col-sm-12 col-md-9 pl-4"
        >
          <b-row>
            <b-col md="12">
              <h3>
                <span
                  class="aiops_mineral_green_heading3"
                  style="text-transform: uppercase;"
                >
                  Hi,
                </span>
                <span
                  class="aiops_mineral_green_heading3"
                  style="text-transform: uppercase;"
                >
                  {{ this.$store.state.productAiops.aiops.user }}
                </span>
              </h3>
              <span
                class="aiops_neutral_blue_heading1"
                style="text-transform: uppercase;"
              >
                Welcome to your ticketing assistant
              </span>
            </b-col>
            <!-- <b-col>
              <div class="mainContainer margin-top-64">
                <div class="details">
                  <div
                    class="aiops_welcome_teis_data"
                  >
                    <span class="aiops_paragraph_6f9ca8_bold aiops_welcome_teis_data_content">Assigned</span>
                    <span class="aiops_label_info_data aiops_welcome_teis_data_content">9</span>
                  </div>
                  <div
                    class="aiops_welcome_teis_data"
                  >
                    <span class="aiops_paragraph_6f9ca8_bold aiops_welcome_teis_data_content">Working on</span>
                    <span class="aiops_label_info_data aiops_welcome_teis_data_content">10</span>
                  </div>
                  <div
                    class="aiops_welcome_teis_data"
                  >
                    <span class="aiops_paragraph_6f9ca8_bold aiops_welcome_teis_data_content">Pending</span>
                    <span class="aiops_label_info_data aiops_welcome_teis_data_content">10</span>
                  </div>
                  <div
                    class="aiops_welcome_teis_data"
                  >
                    <span class="aiops_paragraph_6f9ca8_bold aiops_welcome_teis_data_content">Recategorised</span>
                    <span class="aiops_label_info_data aiops_welcome_teis_data_content">10</span>
                  </div>
                </div>
              </div>
            </b-col> -->
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BImg,
} from 'bootstrap-vue'

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
  },
  computed: {
    checkCurrentPage() {
      return this.$router.currentRoute.name === 'aiops-home' || this.$router.currentRoute.name === 'aiops-home-data-graphics'
    },
  },
}
</script>

<style lang="scss" scoped>
.mainContainer {
  background-color: #eef7f9;
  width: 33.33vw;
  border: #e2efef solid 1px;
  font-family: "Open Sans", Helvetica, Arial, serif;
  border-radius: 1vh;
  overflow: hidden;
}

.details {
  display: flex;
  width: 33.33vw;
  justify-content: center;
  align-items: center;
  padding: 16px;
  background-color: #eef7f9;
}

.aiops_welcome_teis_data {
  display: flex;
  flex-direction: column;
  width: 8.33vw;
}

.aiops_welcome_teis_data:nth-child(-n+3) {
  border-right: 1px solid #CCE3E4;
}

.col .aiops_welcome_teis_data {
  width: 8.33vw;
}
</style>
