<template>
  <nav-bar-product :logo="logoTEIS">
    <template
      v-if="isLogged"
      #nav-items
    >
      <b-nav-item
        :to="aiopsNav[0].path"
        class="nav-item-custom"
        link-classes="nav-link-custom"
        active-class="active-class-custom"
      >
        <span
          class="align-middle nav-item-text"
        >{{ aiopsNav[0].name }}</span>
      </b-nav-item>
      <b-nav-item
        v-for="(item) in dropdownNavWithOutChildren"
        :key="item.name"
        :to="item.path"
        class="nav-item-custom"
        :style="$ability.cannot(item.action) ? 'display: none' : ''"
        link-classes="nav-link-custom"
        active-class="active-class-custom"
      >
        <span
          class="align-middle nav-item-text"
        >{{ item.name }}</span>
      </b-nav-item>

      <b-nav-item-dropdown
        v-for="(dropItem) in dropdownNavWithChildren"
        id="my-nav-dropdown"
        :key="dropItem.header"
        toggle-class="nav-link-custom"
        class="nav-item-custom"
        :style="$ability.cannot(dropItem.action) ? 'display: none' : ''"
        active-class="active-class-custom"
        :text="dropItem.header"
        right
      >
        <b-dropdown-item
          v-for="(childrenItem, i) in dropItem.children"
          :key="i"
          :to="childrenItem.path"
          link-class="inner-links"
        >
          <b-img
            :class="childrenItem.icon"
          />
          <span
            class="aiops_label_14_3e7282"
          >{{ childrenItem.title }}</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </template>
    <template #right-nav-items>
      <!-- <div
        v-if="isLogged"
        class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
      >
        <b-nav-form>
          <span
            class="mr-2 navbaritem"
          >
            Scoped
          </span>
          <b-form-select
            v-model="selected"
            :options="options"
          />
        </b-nav-form>
      </div>

      <AiopsLang /> -->

      <AiopsProfile />
    </template>
  </nav-bar-product>
</template>

<script>
import {
  BNavbar,
  BCollapse,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BNavForm,
  BNavItem,
  BNavItemDropdown,
  BDropdownItem,
  BFormSelect,
  BDropdown,
  BImg,
} from 'bootstrap-vue'
import aiopsNav from '@/navigation/horizontal/aiops'
import NavBarProduct from '../../../components/nav/navBar-product/NavBarProduct.vue'
import AiopsLang from './AiopsLang.vue'
import AiopsProfile from './AiopsProfile.vue'

const logoTEIS = require('@/assets/images/aiops/logo/TicketEnrichment_Logo.svg')

export default {
  components: {
    BNavbar,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BNavForm,
    BNavItem,
    BNavItemDropdown,
    BDropdownItem,
    BFormSelect,
    AiopsLang,
    AiopsProfile,
    NavBarProduct,
    BDropdown,
    BImg,
  },
  data() {
    return {
      aiopsNav,
      logoTEIS,
      selected: null,
      options: [
        { value: null, text: 'Please select some item' },
        { value: 'IT Tickets', text: 'IT Tickets' },
        { value: 'Human Resources', text: 'Human Resources' },
      ],
      logged: false,
    }
  },
  computed: {
    isLogged() {
      return this.verifyIsLogged()
    },
    dropdownNavWithChildren() {
      return aiopsNav.filter(item => item.children)
    },
    dropdownNavWithOutChildren() {
      return aiopsNav.filter(item => !item.children && item.name !== 'HOME')
    },
  },
  methods: {
    verifyIsLogged() {
      this.logged = this.$store.state.productAiops.aiops.logged
      return this.logged
    },
  },
}
</script>
<style lang="scss" >

.bg {
  background: #f2f6f7 !important;
  display: flex;
}

.ibop-menu {
  display: flex;
  justify-content: space-around;
}

.navbaritem {
  color: var(--bismark) !important;
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-size: 0.77vw !important;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 14px;
}
.nav-link-custom {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}
.nav-item-custom, .nav-link-custom {
  height: inherit;
}
.nav-link-custom {
  line-height: 14px;
  font-weight: 600 !important;
  color: var(--bismark) !important;
}
.active-class-custom {
  background-color: var(--persian-green) !important;
}
.active-class-custom > span {
  color: var(--white) !important;
}

// .nav-link:focus {
//   text-decoration: none;
//   background-color: var(--persian-green) !important;
//   color: #fff !important;
// }

.inner-links {
  color: var(--bismark);
  font-size: 0.77vw!important;
  font-weight: 500 !important;
  }
.inner-links:hover {
  background-color: rgba(0, 170, 155, 0.12) !important;
  color: var(--persian-green) !important;
}

::v-deep .dropdown-toggle::after {
  display: none;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}

</style>
