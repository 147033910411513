<template>
  <b-nav-item-dropdown
    v-if="isLogged"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <b-img
        class="businessplatformlogoDrop"
        :src="profile"
      />
    </template>

    <!-- <b-dropdown-item
      :to="{ name: 'pages-profile'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-item
      :to="{ name: 'pages-account-setting' }"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="isLogged"
      link-class="d-flex align-items-center"
      @click="reload"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import { BNavItemDropdown, BDropdownItem, BImg } from 'bootstrap-vue'
import store from '@/store'
// import { initialAbility } from '@/libs/acl/config'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BImg,
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      profile: require('@/assets/images/icons/user_blue.svg'),
    }
  },
  computed: {
    isLogged() {
      return this.verifyIsLogged()
    },
  },
  methods: {
    reload() {
      window.location.reload(true)
      this.logout()
    },
    logout() {
      store.dispatch('productAiops/errorRequest')
    },
    verifyIsLogged() {
      this.logged = this.$store.state.productAiops.aiops.logged
      return this.logged
    },
  },
}
</script>

<style lang="scss" scoped>
.aiops_profile_icon {
  color: #005573;
}

::v-deep .dropdown-toggle::after {
  display: none;
}

.businessplatformlogoDrop {
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  height: 20px;
  width: 20px;
}

.dropdown {
  position: relative;
  display: flex;
  align-items: center;
}
</style>
