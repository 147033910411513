<template>
  <div
    class="col-md-12 backLogin pl-0 margin-bottom-48"
  >
    <b-row
      class="col-md-10"
      align-h="center"
    >
      <nav-bar-position>
        <template #nav-position-items>
          <b-row class="aiops-main-item-brow">
            <div
              id="idcol"
              class="mintui-nav-position-column-principal"
            >
              <b-img
                class="businessplatformlogo"
                :class="`mintui_${breadCrumb.icon}`"
              />
              <span class="aiops_neutral_blue_heading3 px-1 padding-top-4 nav-position-text-uppercase">
                {{ breadCrumb.name }}
              </span>
            </div>
            <div
              id="idcol"
              class=" mintui-nav-position-column-secondary pl-1"
            >
              <b-img
                class="mintui_icons8_home businessplatformlogo mintui_cursor_pointer"
                @click="goHome"
              />
            </div>
            <div
              v-for="(item, i) in breadCrumb.root"
              id="idcol"
              :key="i"
              class=" mintui-nav-position-column-secondary"
            >
              <span class="aiops_paragraph_6f9ca8 px-1 padding-top-4">></span>
              <span class="aiops_paragraph_6f9ca8 pr-1 padding-top-4">{{ item.name }}</span>
            </div>
          </b-row>
        </template>
      </nav-bar-position>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol, BImg } from 'bootstrap-vue'
import NavBarPosition from '../../../components/nav/navBar-position/NavAiopsComponent.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    NavBarPosition,
  },
  props: {
    breadCrumb: {
      type: Object,
      default() {
        return {
          name: 'Home',
          icon: 'icons8_tag_window_1',
          root: [],
        }
      },
    },
  },
  methods: {
    goHome() {
      this.$router.push({ name: 'aiops-home' })
    },
  },
}
</script>

<style lang="scss" scoped>
.nav-position-text-uppercase {
  text-transform: uppercase;
}
</style>
