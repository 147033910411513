export default [
  {
    name: 'HOME',
    path: '/aiops/home',
    action: 'aiopsadmin',
  },
  // {
  //   name: 'MANAGER HOME',
  //   path: '/data-graphics',
  //   action: 'aiopsadmin',
  // },
  {
    header: 'MODEL MANAGER',
    action: 'aiopsadmin',
    children: [
      {
        title: 'Classification model',
        path: '/model-manager/classification-model',
        icon: 'mintui_icons8_tag_window_1',
        action: 'aiopsadmin',
      },
      // {
      //   title: 'Business automation',
      //   path: '/model-manager/business-automation',
      //   icon: 'mintui_aiops_business_automation',
      //   action: 'aiopsadmin',
      // },
    ],
  },
  {
    header: 'SETTINGS',
    action: 'aiopsadmin',
    children: [
      {
        title: 'Data Source',
        path: '/setting/data-source',
        icon: 'mintui_icons8_e-learning',
        action: 'aiopsadmin',
      },
      {
        title: 'Users',
        path: '/setting/users',
        icon: 'mintui_users_darker',
        action: 'aiopsadmin',
      },
      {
        title: 'Roles',
        path: '/setting/roles',
        icon: 'mintui_icons8_admin_settings_male_darker',
        action: 'aiopsadmin',
      },
      {
        title: 'Knowledge Base',
        path: '/setting/knowledge-base',
        icon: 'mintui_list_medium',
        action: 'aiopsadmin',
      },
    ],
  },
]
