<template>
  <div class="bg col-12">
    <div class="container col-lg-11 pl-0 pr-o">
      <b-navbar
        toggleable="md"
        class="bg py-0"
      >
        <b-navbar-brand href="#">
          <b-img
            class="businessplatformlogo"
            :src="logo"
          />
        </b-navbar-brand>
        <b-navbar-toggle
          target="nav-collapse"
        />
        <b-collapse
          id="nav-collapse"
          class=" ibop-menu"
          is-nav
        >
          <b-navbar-nav class="nav-items-menu">
            <slot name="nav-items" />
          </b-navbar-nav>
          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <slot name="right-nav-items" />
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import {
  BNavbar,
  BCollapse,
  BNavbarBrand,
  BNavbarToggle,
  BNavbarNav,
  BImg,
} from 'bootstrap-vue'
/* eslint-disable global-require */
export default {
  components: {
    BNavbar,
    BCollapse,
    BNavbarBrand,
    BNavbarToggle,
    BNavbarNav,
    BImg,
  },
  props: {
    logo: {
      type: String,
      default: require('@/assets/images/logo/inetum_logo.svg'),
    },
  },
  data() {
    return {
      selected: null,
      options: [
        { value: null, text: 'Please select some item' },
        { value: 'IT Tickets', text: 'IT Tickets' },
        { value: 'Human Resources', text: 'Human Resources' },
      ],
    }
  },
}
</script>
<style lang="scss" scoped>

.bg {
  background: var(--catskill-white) !important;
  display: flex;
}
#nav-collapse {
  min-height: 50px;
}

.ibop-menu {
  display: flex;
  justify-content: space-around;
}

.businessplatformlogo {
  background-color: transparent;
  padding: 10px;
  height: 100%;
  left: 0px;
  top: 0px;
  width: 12.2vw;
}

.navbaritem {
  color: var(--bismark) !important;
  font-family: "Open Sans", Helvetica, Arial, serif;
  font-size: 0.77vw;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  line-height: 0.77vw;
  margin-right: 20px !important;
}

.ibop-item-menu:hover {
  border: 0px solid rgb(0, 170, 155);
  background: transparent;
  color: rgb(0, 170, 155);
}

.nav-items-menu {
  font-size: 0.77vw;
  height: 3vw;
  width: 17.05vw;
  margin-left: 5%;
  align-items: center;
  flex-grow: 1;
}

::v-deep .dropdown-toggle::after {
  display: none;
}

</style>
